import { useEffect, useState } from "react";
import animation1 from "../../assets/models/dance10.glb";
import animation2 from "../../assets/models/hiphop.glb";
import animation3 from "../../assets/models/booty-dance.glb";
import animation4 from "../../assets/models/gangnam-style.glb";
import animation5 from "../../assets/models/chicken-dance.glb";
import animation6 from "../../assets/models/slide-dance.glb";
import animation7 from "../../assets/models/TutHipHopDance.glb";
import animation8 from "../../assets/models/wave-hip-hop.glb";
import animation9 from "../../assets/models/YmcaDance.glb";
import animation10 from "../../assets/models/gangnam-style.glb";
import Idle from "../../assets/models/idle.glb";
import Jumping from "../../assets/models/jumping.glb";
import Running from "../../assets/models/running.glb";
import walking from "../../assets/models/walking.glb";
import walkingBack from "../../assets/models/walking-back.glb";
import walkingLeft from "../../assets/models/walk-left.glb";
import walkingRight from "../../assets/models/walk-right.glb";
import Sitdown from "../../assets/models/sitdown.glb";
import SitdownIdle from "../../assets/models/sitdownIdle.glb";
import { findAncestorWithComponent } from "../../utils/scene-graph";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { setMatrixWorld } from "../../utils/three-utils";

// The page is styled by default not to have any outlines on selected input elements.
// When the user presses the tab key the keyboardUserClass is added to the body and all outlines are re-enabled.
// The next time you click this keyboardUserClass will be removed, removing the outline styles.
// This lets us provide a clean look for mouse users, while still maintaining accessibility for keyboard users.

let currentAction, currentNetworkId, myCurrentAction;
let animations = [];
let currenTimeOut;
let AnimationDances = [
  animation1,
  animation2,
  animation3,
  animation4,
  animation5,
  animation6,
  animation7,
  animation8,
  animation9,
  animation10
];

let keyUp = false;
let keyDown = false;
let keyLeft = false;
let keyRight = false;
let positionCharacter = 0;
let isFirstF = false;
function checkCurrentNetworkId(networkId, newAction) {
  let a;
  animations.find(e => {
    if (networkId == e.networkId) {
      a = e.action;
    }
  });
  if (a) {
    return a;
  } else {
    animations.push({
      action: newAction,
      networkId: networkId
    });
    return a;
  }
}
export function switchAction(newAction, networkId) {
  if (animations.length === 0) {
    animations.push({
      action: newAction,
      networkId: networkId
    });
  } else if (networkId != currentNetworkId) {
    currentAction = checkCurrentNetworkId(networkId, newAction);
  }
  if (newAction != currentAction) {
    if (currentAction) {
      currentAction.fadeOut(0.3);
    }
    newAction.reset();
    newAction.setEffectiveWeight(1);
    newAction.play();
    newAction.fadeIn(0.3);
    currentAction = newAction;
    animations.find(e => {
      if (networkId == e.networkId) {
        e.action = newAction;
      }
    });
    currentNetworkId = networkId;
  }
}

export function loadNewAnimation(mixer, arrayBuffer, loop) {
  const gltf_loader = new GLTFLoader();
  gltf_loader.parse(arrayBuffer, "", gltf => {
    const action = mixer.clipAction(gltf.animations[0]);
    let newAction = mixer.clipAction(gltf.animations[0]);
    if (loop) {
      action.setLoop(THREE.LoopOnce);
      const currentTimeOut = setTimeout(() => {
        const IdleAnimation = checkMale() ? globalAnimationsMale.Idle : globalAnimations.Idle;
        loadNewAnimation(mixer, IdleAnimation);
      }, action._clip.duration * 1000 - 500);
    }
    switchMyAction(newAction, mixer);
  });
}

export function loadMyAnimation(mixer, file, loop) {
  const gltf_loader = new GLTFLoader();
  gltf_loader.load(file, function (gltf) {
    const action = mixer.clipAction(gltf.animations[0]);
    let newAction = mixer.clipAction(gltf.animations[0]);
    if (loop) {
      action.setLoop(THREE.LoopOnce);
      currenTimeOut = setTimeout(() => {
        loadMyAnimation(mixer, Idle);
      }, action._clip.duration * 1000 - 500);
    }
    switchMyAction(newAction);
  });
}

export function switchMyAction(newAction, mixer) {
  if (newAction != myCurrentAction) {
    if (myCurrentAction) {
      myCurrentAction.fadeOut(0.3);
    }
    newAction.reset();
    newAction.setEffectiveWeight(1);
    newAction.play();
    newAction.fadeIn(0.3);
    myCurrentAction = newAction;
  }
}

export function sendAnimation(keyCode, animationName, AnimationLoop) {
  let dataAnimation = JSON.stringify({
    code: keyCode,
    networkId: window.sessionStorage.getItem("networkId"),
    animationName: animationName,
    AnimationLoop: AnimationLoop
  });
  window.APP.hubChannel.sendMessage(`${dataAnimation}`, "animation");
}

export function sendAnimationNew(keyCode, animationName, AnimationLoop) {
  const dataView = new DataView(animationName);
  const bytes = new Uint8Array(dataView.buffer);

  const animationData = {
    code: keyCode,
    networkId: window.sessionStorage.getItem("networkId"),
    animationName: bytes,
    AnimationLoop: AnimationLoop
  };

  window.APP.hubChannel.sendMessage(animationData, "animation");
}

export function sendPosition(cameraQuaternion, myNetworkId, type) {
  let dataPosition = JSON.stringify({
    cameraQuaternion: cameraQuaternion,
    myNetworkId: myNetworkId,
    type: type
  });
  window.APP.hubChannel.sendMessage(`${dataPosition}`, "position");
}

export function checkMale(mixer) {
  let head = sessionStorage.getItem("Head");
  return head < 0.6 ? false : true;
}

// function eventMove(rotator, currenTimeOut, e, mixer) {
//   if (rotator) {
//     window.sessionStorage.setItem(rotator, true);
//   }
//   clearTimeout(currenTimeOut);
//   if (!e.shiftKey) {
//     sendAnimation(e.keyCode, "walking");
//     // loadNewAnimation(mixer, globalAnimations.walking);

//     if (checkMale(mixer)) {
//       loadNewAnimation(mixer, globalAnimationsMale.walking);
//     } else {
//       loadNewAnimation(mixer, globalAnimations.walking);
//     }
//   } else {
//     sendAnimation(e.keyCode, "Running");
//     // loadNewAnimation(mixer, globalAnimations.Running);
//     if (checkMale(mixer)) {
//       loadNewAnimation(mixer, globalAnimationsMale.Running);
//     } else {
//       loadNewAnimation(mixer, globalAnimations.Running);
//     }
//   }
// }

function eventMove(rotator, currenTimeOut, e, mixer) {
  if (rotator) {
    window.sessionStorage.setItem(rotator, true);
  }
  clearTimeout(currenTimeOut);

  const animationType = e.shiftKey ? "Running" : "walking";
  const animation = checkMale(mixer) ? globalAnimationsMale[animationType] : globalAnimations[animationType];

  sendAnimation(e.keyCode, animationType);
  loadNewAnimation(mixer, animation);
}

function checkKeyPress(isWalking, data, currenTimeOut, e, mixer, isSpine1Present, keyState) {
  const actions = [
    {
      condition: keyUp,
      action: () => {
        if (isSpine1Present && !keyFly) {
          isWalking.isWalking = true;
          eventMove(false, currenTimeOut, e, mixer);
        } else {
          isWalking.isWalking = keyUp;
        }
        window.sessionStorage.setItem("isWalking", JSON.stringify(data));
      }
    },
    {
      condition: keyDown,
      action: () => {
        if (isSpine1Present && !keyFly) {
          eventMove("isBack", currenTimeOut, e, mixer);
        } else {
          window.sessionStorage.setItem("isBack", keyDown);
        }
      }
    },
    {
      condition: keyRight,
      action: () => {
        if (isSpine1Present && !keyFly) {
          eventMove("isLeft", currenTimeOut, e, mixer);
        } else {
          window.sessionStorage.setItem("isLeft", keyRight);
        }
      }
    },
    {
      condition: keyLeft,
      action: () => {
        if (isSpine1Present && !keyFly) {
          eventMove("isRight", currenTimeOut, e, mixer);
        } else {
          window.sessionStorage.setItem("isRight", keyLeft);
        }
      }
    }
  ];

  actions.forEach(action => {
    if (action.condition) {
      action.action();
    }
  });
}

function setKeyPress(e, isSpine1Present, data, isWalking, keyState) {
  switch (e.key) {
    case "ArrowUp":
    case "w":
      keyUp = keyState;
      break;
    case "ArrowDown":
    case "s":
      keyDown = keyState;
      break;
    case "ArrowLeft":
    case "a":
      keyLeft = keyState;
      break;
    case "ArrowRight":
    case "d":
      keyRight = keyState;
      break;
  }
}

export function loadJumpingAndThenArrayBuffer(mixer, arrayBuffer, loop) {
  const loadJumpingAnimation = () => {
    const gltf_loader = new GLTFLoader();
    gltf_loader.parse(globalAnimations.Jumping, "", gltf => {
      const action = mixer.clipAction(gltf.animations[0]);
      let newAction = mixer.clipAction(gltf.animations[0]);

      // Tính thời gian chạy 1 nửa của animation "Jumping"
      const halfwayTime = action._clip.duration * 0.2;

      action.play();
      action.setLoop(THREE.LoopOnce, 1);
      action.clampWhenFinished = true;
      let positionEnd = 0;
      const updatePositionY = deltaY => {
        let avatarPOV = document.getElementById("avatar-pov-node");
        let avatarRig = document.getElementById("avatar-rig");
        let viewingRig = document.getElementById("viewing-rig");
        const position = new THREE.Vector3();
        const quat = new THREE.Quaternion();
        const scale = new THREE.Vector3();
        viewingRig.object3D.updateMatrices();
        viewingRig.object3D.matrixWorld.decompose(position, quat, scale);
        position.y = position.y + deltaY;
        positionEnd = position.y;
        if (position.y <= 0 && !keyFly) {
          position.y = 0;
        }
        setMatrixWorld(avatarRig.object3D, new THREE.Matrix4().compose(position, quat, scale));
        avatarPOV.object3D.updateMatrices();
      };

      let steps = 30;
      const deltaY = 3.6 / steps;
      for (let i = 1; i <= steps; i++) {
        if (i == steps && positionEnd > 0) {
          steps += 1;
        }
        setTimeout(() => {
          positionCharacter += deltaY;
          updatePositionY(deltaY);
        }, ((halfwayTime * 1000) / steps) * i);
      }

      setTimeout(() => {
        if (keyFly) {
          loadNewAnimation(mixer, arrayBuffer, loop);
        }
      }, halfwayTime * 1000);

      switchMyAction(newAction, mixer);
    });
  };

  loadJumpingAnimation();
}

function jumpinghalf(mixer, arrayBuffer, loop) {
  const gltf_loader = new GLTFLoader();
  gltf_loader.parse(globalAnimations.Jumping, "", gltf => {
    const action = mixer.clipAction(gltf.animations[0]);
    const halfwayTime = action._clip.duration * 0.2;

    action.play();
    action.setLoop(THREE.LoopOnce, 1);
    action.clampWhenFinished = true;

    setTimeout(() => {
      action.stop();
    }, halfwayTime * 1000);
  });
}

export function useAccessibleOutlineStyle(
  keyboardUserClass = "keyboard-user",
  props,
  CAMERA_MODE_THIRD_PERSON_VIEW,
  CAMERA_MODE_FIRST_PERSON
) {
  const [keyboardUser, setKeyboardUser] = useState(false);
  const [setting, setSetting] = useState(false);
  let key = null;
  let moveEvent = [];
  let firtButton = null;

  useEffect(() => {
    function onMouseDown() {
      if (keyboardUser) {
        document.body.classList.remove(keyboardUserClass);
        setKeyboardUser(false);
      }
    }
    let i = 0;

    const performJumpingAnimation = async mixer => {
      const halfwayTime = globalAnimations.JumpingDuration * 0.5;
      const animation = checkMale() ? globalAnimationsMale.flyTest : globalAnimations.flyTest;
      loadJumpingAndThenArrayBuffer(mixer, animation, halfwayTime);
      let steps = 30;
      const deltaY = positionCharacter / steps;
      let positionEnd = 0;
      const updatePositionY = (deltaY, end) => {
        let avatarPOV = document.getElementById("avatar-pov-node");
        let avatarRig = document.getElementById("avatar-rig");
        let viewingRig = document.getElementById("viewing-rig");
        const position = new THREE.Vector3();
        const quat = new THREE.Quaternion();
        const scale = new THREE.Vector3();

        viewingRig.object3D.updateMatrices();
        viewingRig.object3D.matrixWorld.decompose(position, quat, scale);

        const meshY = window.sessionStorage.getItem("meshY");
        position.y = 0;
        positionEnd = position.y;
        if (positionEnd <= 0) {
          const myNetworkId = window.sessionStorage.getItem("networkId");
          const avatarNew = NAF?.connection?.entities?.entities[myNetworkId]?.querySelector(".AvatarRoot")?.object3D;
          const cameraYQuaternion = JSON.parse(window.sessionStorage.getItem("cameraYQuaternion"));
          avatarNew?.quaternion?.slerpQuaternions(avatarNew?.quaternion, cameraYQuaternion, 1000);
          avatarNew?.rotation.set(0, cameraYQuaternion._y, 0);

          if (
            parseFloat(JSON.parse(window.sessionStorage.getItem("currentRotation")))?.toFixed(4) !==
            cameraYQuaternion._y?.toFixed(4)
          ) {
            sendPosition(cameraYQuaternion, myNetworkId, "go");
          }
          window.sessionStorage.setItem("currentRotation", cameraYQuaternion._y);
          setMatrixWorld(avatarRig.object3D, new THREE.Matrix4().compose(position, quat, scale));
          avatarPOV.object3D.updateMatrices();
          return;
        }
        setMatrixWorld(avatarRig.object3D, new THREE.Matrix4().compose(position, quat, scale));
        avatarPOV.object3D.updateMatrices();
      };

      for (let i = 1; i <= steps; i++) {
        if (i == steps && positionEnd > 0) {
          steps += 1;
        }
        await new Promise(resolve => setTimeout(resolve, (halfwayTime * 1000) / steps));
        updatePositionY(deltaY);
      }
      i = 0;

      // Đặt lại giá trị cuối cùng
      await new Promise(resolve =>
        setTimeout(() => {
          updatePositionY(deltaY, true);
          resolve();
        }, halfwayTime * 1000)
      );
    };

    async function onKeyDown(e) {
      teleport = false;
      let myNetworkId;
      myNetworkId = window.sessionStorage.getItem("networkId");
      const networkEntities = NAF?.connection?.entities?.entities?.[myNetworkId];
      if (!networkEntities) {
        return;
      }

      const mixerEl = findAncestorWithComponent(networkEntities?.querySelector(".AvatarRoot"), "animation-mixer");
      const mixer = mixerEl && mixerEl.components["animation-mixer"].mixer;
      const data = JSON.parse(window.sessionStorage.getItem("isWalking"));

      if (e.target && e.target.id === "chat-input") {
        return;
      }
      const isSpine1Present = networkEntities.querySelector(".Spine1");
      const isWalking = data?.find(entry => entry.networkId === myNetworkId);
      if (!isSpine1Present) {
        setKeyPress(e, isSpine1Present, data, isWalking, true);
        checkKeyPress(isWalking, data, currenTimeOut, e, mixer, isSpine1Present, true);
        return;
      }
      switch (e.key) {
        case "v":
          props.scene.systems["hubs-systems"].cameraSystem.setMode(
            props.scene.systems["hubs-systems"].cameraSystem.mode === 5
              ? CAMERA_MODE_FIRST_PERSON
              : CAMERA_MODE_THIRD_PERSON_VIEW
          );
          break;
        case "ArrowUp":
        case "w":
          if (isSpine1Present && e.key !== key && !keyFly) {
            moveEvent.push("ArrowUp");
            eventMove(false, currenTimeOut, e, mixer);
          }
          keyUp = true;
          isWalking.isWalking = true;
          window.sessionStorage.setItem("isWalking", JSON.stringify(data));
          break;
        case "j":
          if (e.key !== key && !keyFly) {
            clearTimeout(currenTimeOut);
            sendAnimation(e.keyCode, "Jumping", THREE.LoopOnce);
            const JumpAnimation = checkMale() ? globalAnimationsMale.Jumping : globalAnimations.Jumping;
            loadNewAnimation(mixer, JumpAnimation, THREE.LoopOnce);
          }
          break;
        case "ArrowDown":
        case "s":
          if (isSpine1Present && e.key !== key && !keyFly) {
            moveEvent.push("ArrowDown");
            eventMove("isBack", currenTimeOut, e, mixer);
          }
          keyDown = true;
          window.sessionStorage.setItem("isBack", true);
          break;
        // case "x":
        //   if (e.key !== key && !keyFly) {
        //     clearTimeout(currenTimeOut);
        //     sendAnimation(e.keyCode, "SitdownIdle");
        //     loadNewAnimation(mixer, globalAnimations.SitdownIdle);
        //   }
        //   break;
        case "ArrowLeft":
        case "a":
          if (isSpine1Present && e.key !== key && !keyFly) {
            moveEvent.push("ArrowLeft");
            eventMove("isRight", currenTimeOut, e, mixer);
          }
          keyLeft = true;
          window.sessionStorage.setItem("isRight", true);
          break;
        case "ArrowRight":
        case "d":
          if (isSpine1Present && e.key !== key && !keyFly) {
            moveEvent.push("ArrowRight");
            eventMove("isLeft", currenTimeOut, e, mixer);
          }
          keyRight = true;
          window.sessionStorage.setItem("isLeft", true);
          break;
        case "f":
          if (isSpine1Present && e.key !== key) {
            if (!keyFly) {
              keyFly = true;
              APP.scene.systems["hubs-systems"].characterController.fly = true;
              sendAnimation(e.keyCode, "flyTest");
              const flyAnimation = checkMale() ? globalAnimationsMale.flyTest : globalAnimations.flyTest;
              loadJumpingAndThenArrayBuffer(mixer, flyAnimation);
              hasRun = false;
            } else {
              keyFly = false;
              APP.scene.systems["hubs-systems"].characterController.fly = false;
              const jumpingAnimation = checkMale() ? globalAnimationsMale.Jumping : globalAnimations.Jumping;
              loadNewAnimation(mixer, jumpingAnimation);
              performJumpingAnimation(mixer);
            }
          }
          break;
      }

      if (e.keyCode > 47 && e.keyCode < 58 && isSpine1Present) {
        const animations = checkMale() ? AnimationDanceMale : AnimationDance;
        const animationIndex = parseInt(e.key);

        loadNewAnimation(mixer, animations[animationIndex].animation);
        sendAnimation(e.keyCode, animations[animationIndex].name);
      }

      key = e.key;
      if (e.key === "Tab" && !keyboardUser) {
        document.body.classList.add(keyboardUserClass);
        setKeyboardUser(true);
      }
    }

    async function onKeyUp(e) {
      const myNetworkId = window.sessionStorage.getItem("networkId");
      const isWalkingData = JSON.parse(window.sessionStorage.getItem("isWalking"));

      const entityToModify = isWalkingData.find(entry => entry.networkId === myNetworkId);
      if (entityToModify) {
        entityToModify.isWalking = false;
      }

      window.sessionStorage.setItem("isBack", false);
      window.sessionStorage.setItem("isRight", false);
      window.sessionStorage.setItem("isLeft", false);
      window.sessionStorage.setItem("isWalking", JSON.stringify(isWalkingData));

      const networkEntities = NAF?.connection?.entities?.entities?.[myNetworkId];
      if (!networkEntities) {
        return;
      }
      const isSpine1Present = networkEntities.querySelector(".Spine1");
      const data = JSON.parse(window.sessionStorage.getItem("isWalking"));
      const isWalking = data?.find(entry => entry.networkId === myNetworkId);

      if (!NAF?.connection?.entities?.entities?.[myNetworkId]?.querySelector(".Spine1")) {
        setKeyPress(e, isSpine1Present, data, isWalking, false);
        checkKeyPress(isWalking, data, currenTimeOut, e, false, isSpine1Present, false);
        return;
      }

      sendPosition(null, myNetworkId, "keyUp");

      const mixerEl = findAncestorWithComponent(
        NAF?.connection?.entities?.entities?.[myNetworkId]?.querySelector(".AvatarRoot"),
        "animation-mixer"
      );
      const mixer = mixerEl && mixerEl.components["animation-mixer"].mixer;

      if (!keyFly && !["j", "Enter", "x"].includes(e.key) && (e.keyCode < 47 || e.keyCode > 58)) {
        const animation = checkMale(mixer) ? globalAnimationsMale.Idle : globalAnimations.Idle;
        sendAnimation(e.keyCode, "Idle");
        loadNewAnimation(mixer, animation);
      }

      setKeyPress(e, isSpine1Present, data);
      checkKeyPress(isWalking, data, currenTimeOut, e, mixer, isSpine1Present);

      key = null;
      if (e.key === "Tab" && !keyboardUser) {
        document.body.classList.add(keyboardUserClass);
        setKeyboardUser(true);
      }
    }
    function onDocumentMouseWheel(event) {
      if (event.deltaY > 0) {
        if (Math.abs(APP.scene.camera.position.z) >= 0.4) {
          zoom -= 0.1;
        } else {
          props.scene.systems["hubs-systems"].cameraSystem.setMode(CAMERA_MODE_FIRST_PERSON);
        }
      } else {
        props.scene.systems["hubs-systems"].cameraSystem.setMode(CAMERA_MODE_THIRD_PERSON_VIEW);
        zoom += 0.1;
      }
    }

    window.addEventListener("keydown", onKeyDown);
    window.addEventListener("keyup", onKeyUp);
    window.addEventListener("mousedown", onMouseDown);
    window.addEventListener("wheel", onDocumentMouseWheel);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
      window.removeEventListener("keyup", onKeyUp);
      window.removeEventListener("mousedown", onMouseDown);
      window.removeEventListener("wheel", onDocumentMouseWheel);
    };
  }, [keyboardUserClass, keyboardUser]);
}
